import React from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { SearchDropdown, SearchDropdownMenuOption, TextField } from '@skiwo/components';
import getGenderName from '../../../helpers/getGenderName';
import useDebounce from '../../../hooks/useDebounce';
import { useQualifications } from '../../../providers/QualificationsProvider';
import translationKeys from '../../../translations/translationKeys';
import { InterpretationSkillSex } from '../../../types';
import styles from './InterpretersTimeline.module.scss';

export enum InterpretersTimelineFilterField {
  Name = 'name',
  Qualification = 'qualification',
  Gender = 'gender',
  Distance = 'distance',
  AlternativeLanguage = 'alternativeLanguage',
  StandbyInterpreters = 'standbyInterpreters',
}

interface InterpretersTimelineFiltersProps {
  filters: Record<string, string | string[] | undefined>;
  onFilterChange: (field: InterpretersTimelineFilterField, value: string | string[]) => void;
}

export const InterpretersTimelineFilters = ({
  onFilterChange,
}: InterpretersTimelineFiltersProps) => {
  const intl = useIntl();
  const { qualifications } = useQualifications();
  const debounceFilterChange = useDebounce(300);

  const qualificationOptions =
    qualifications
      .filter((qualification) => qualification.industry === 'interpretation')
      .map((qualification) => {
        return {
          id: qualification.id,
          label: qualification.name || '',
          key: qualification.id.toString(),
        };
      }) || [];

  const distanceOptions: SearchDropdownMenuOption[] = [
    {
      id: 1,
      label: intl.formatMessage({
        id: translationKeys.job_interpreter_timeline_filters_distance_30,
      }),
      key: '30',
    },
    {
      id: 2,
      label: intl.formatMessage({
        id: translationKeys.job_interpreter_timeline_filters_distance_70,
      }),
      key: '70',
    },
    {
      id: 3,
      label: intl.formatMessage({
        id: translationKeys.job_interpreter_timeline_filters_distance_100,
      }),
      key: '100',
    },
    {
      id: 4,
      label: intl.formatMessage({
        id: translationKeys.job_interpreter_timeline_filters_distance_150,
      }),
      key: '150',
    },
    {
      id: 5,
      label: intl.formatMessage({
        id: translationKeys.job_interpreter_timeline_filters_distance_200,
      }),
      key: '200',
    },
    {
      id: 6,
      label: intl.formatMessage({
        id: translationKeys.job_interpreter_timeline_filters_distance_300,
      }),
      key: '300',
    },
  ];

  const handleFilterChange = (field: InterpretersTimelineFilterField, value: string | string[]) => {
    debounceFilterChange(() => {
      onFilterChange(field, value);
    });
  };

  return (
    <div className={styles.filters}>
      <TextField
        name="name"
        placeholder={intl.formatMessage({
          id: translationKeys.job_interpreter_timeline_filters_name_placeholder,
        })}
        className={styles.nameFilter}
        onChange={(e) => handleFilterChange(InterpretersTimelineFilterField.Name, e.target.value)}
      />
      <div className={styles.qualificationFilter}>
        <SearchDropdown
          multiple
          placeholder={intl.formatMessage({
            id: translationKeys.job_interpreter_timeline_filters_qualification_level_placeholder,
          })}
          options={qualificationOptions}
          onChange={(qualifications) => {
            if (qualifications && qualifications.length > 0) {
              const qualificationIds = qualifications.map((qualification) =>
                qualification.id.toString(),
              );
              handleFilterChange(InterpretersTimelineFilterField.Qualification, qualificationIds);
            } else {
              handleFilterChange(InterpretersTimelineFilterField.Qualification, []);
            }
          }}
          // onChange={(qualification) => {
          //   if (qualification && qualification.length > 0 && qualification[0].key) {
          //     handleFilterChange(
          //       InterpretersTimelineFilterField.Qualification,
          //       `${qualification[0].key}`,
          //     );
          //   } else {
          //     handleFilterChange(InterpretersTimelineFilterField.Qualification, '');
          //   }
          // }}
        />
      </div>
      <div className={styles.genderFilter}>
        <SearchDropdown
          placeholder={intl.formatMessage({
            id: translationKeys.job_interpreter_timeline_filters_gender_placeholder,
          })}
          options={[
            {
              id: 1,
              label: getGenderName(InterpretationSkillSex.Female, intl),
              key: InterpretationSkillSex.Female,
            },
            {
              id: 2,
              label: getGenderName(InterpretationSkillSex.Male, intl),
              key: InterpretationSkillSex.Male,
            },
          ]}
          onChange={(gender) => {
            if (gender && gender.length > 0 && gender[0].key) {
              handleFilterChange(InterpretersTimelineFilterField.Gender, `${gender[0].key}`);
            } else {
              handleFilterChange(InterpretersTimelineFilterField.Gender, '');
            }
          }}
        />
      </div>
      <div className={styles.distanceFilter}>
        <SearchDropdown
          placeholder={intl.formatMessage({
            id: translationKeys.job_interpreter_timeline_filters_distance_placeholder,
          })}
          options={distanceOptions}
          onChange={(distance) => {
            if (distance && distance.length > 0 && distance[0].key) {
              handleFilterChange(InterpretersTimelineFilterField.Distance, `${distance[0].key}`);
            } else {
              handleFilterChange(InterpretersTimelineFilterField.Distance, '');
            }
          }}
        />
      </div>
      <Form.Check
        type="checkbox"
        onChange={(e) => {
          handleFilterChange(
            InterpretersTimelineFilterField.AlternativeLanguage,
            e.target.checked.toString(),
          );
        }}
        label={intl.formatMessage({
          id: translationKeys.job_interpreter_timeline_filters_alternative_language_checkbox,
        })}
        className={styles.noSourceLanguageCheckbox}
      />
      <Form.Check
        type="checkbox"
        onChange={(e) => {
          handleFilterChange(
            InterpretersTimelineFilterField.StandbyInterpreters,
            e.target.checked.toString(),
          );
        }}
        label={intl.formatMessage({
          id: translationKeys.job_interpreter_timeline_filters_standby_interpreters_checkbox,
        })}
        className={styles.noSourceLanguageCheckbox}
      />
    </div>
  );
};
