export interface JobInvoice {
  totalAmount: number | null;
  status: string | null;
  templateName: string | null;
  financeType: FinanceType | string | null;
  lines: JobInvoiceLine[] | null;
}

export enum JobInvoiceLineGroup {
  Travel = 'travel',
  Bonus = 'bonus',
  Work = 'work',
}

export enum JobInvoiceLineUnit {
  Hour = 'hour',
  Km = 'km',
}

export enum JobInvoiceLineTravelType {
  TravelDuration = 'travel_duration',
  TravelExpenses = 'travel_expenses',
  TravelExpensesEstimate = 'travel_expenses_estimate',
  TravelDriving = 'travel_driving',
  TravelDiet = 'travel_diet',
  TravelFlatAmount = 'travel_flat_amount',
  TravelDurationPlatformFee = 'travel_duration_platform_fee',
}

interface JobInvoiceLine {
  group: JobInvoiceLineGroup | null;
  type: JobInvoiceLineTravelType | string;
  name: string | null;
  description: string | null;
  quantity: string | null;
  unit: JobInvoiceLineUnit | null;
  unitPrice: string | null;
  amount: string | null;
}

export enum FinanceType {
  Internal = 'internal',
  External = 'external',
  Company = 'company',
  Honorar = 'honorar',
  Employee = 'employee',
}
