import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tag } from '@skiwo/components';
import { TagColor } from '@skiwo/components/src/Tag/Tag';
import styles from './FinanceSection.module.scss';

const FinanceCard = ({
  icon,
  title,
  totalTitle,
  totalSubtitle,
  status,
  tags,
  buttonText,
  onButtonClick,
}: {
  title: string;
  icon: IconDefinition;
  totalTitle: string;
  totalSubtitle: string;
  buttonText: string;
  tags?: { children?: string | null; color: TagColor }[];
  status?: string | null;
  onButtonClick?: () => void;
}) => {
  return (
    <div className={styles.financeCard}>
      <div className={styles.titleRow}>
        <h3 className={styles.title}>
          <FontAwesomeIcon icon={icon} className={styles.icon} /> {title}
        </h3>
        {status && (
          <Tag size="large" color="warning">
            {status}
          </Tag>
        )}
      </div>
      <h2 className={styles.totalTitle}>{totalTitle}</h2>
      <p className={styles.totalSubtitle}>{totalSubtitle}</p>
      <div className={styles.tags}>
        {tags?.map((tag) =>
          tag.children ? (
            <Tag key={tag.children} color={tag.color}>
              {tag.children}
            </Tag>
          ) : null,
        )}
      </div>
      <Button
        className={styles.button}
        variant="white"
        icon={<FontAwesomeIcon icon={icon} />}
        fullWidth
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default FinanceCard;
