import React from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faAddressCard, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, RadioOptions, TextField } from '@skiwo/components';
import { Form, Formik } from 'formik';
import {
  useGetJobQuery,
  useWithdrawInterpreterMutation,
} from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import InterpreterCard from '../../../components/InterpreterCard/InterpreterCard';
import { getTypesafeSetFieldValue } from '../../../helpers/getTypesafeSetFieldValue';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobAcceptedOrInvitedInterpreter,
  ManagerJobWithdrawReason,
} from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import getWithdrawReason from '../../utils/getWithdrawReason';
import styles from './WithdrawModal.module.scss';

interface WithdrawModalProps {
  interpreter: ManagerJobAcceptedOrInvitedInterpreter;
  show: boolean;
  onClose: () => void;
}

enum WithdrawOption {
  MySalita = 'my-salita',
  Interpreter = 'interpreter',
}

interface WithdrawForm {
  withdrawOption?: WithdrawOption;
  withdrawInternalReason?: ManagerJobWithdrawReason;
  withdrawInternalOtherReason?: string;
  withdrawSupplierReason?: ManagerJobWithdrawReason;
  withdrawSupplierOtherReason?: string;
}

export const WithdrawModal = ({ interpreter, show, onClose }: WithdrawModalProps) => {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const withdrawInterpreterMutation = useWithdrawInterpreterMutation();
  const { showErrorToast } = useToast();

  if (!job) return null;

  const withdrawOptions = [
    {
      id: WithdrawOption.MySalita,
      icon: <FontAwesomeIcon icon={faAddressCard} />,
      title: <FormattedMessage id={translationKeys.job_withdraw_modal_my_salita} />,
    },
    {
      id: WithdrawOption.Interpreter,
      icon: <FontAwesomeIcon icon={faUser} />,
      title: <FormattedMessage id={translationKeys.job_withdraw_modal_interpreter} />,
    },
  ];

  const handleSubmit = (values: WithdrawForm) => {
    if (
      !(
        jobId &&
        values.withdrawOption &&
        (values.withdrawSupplierReason || values.withdrawInternalReason)
      )
    )
      return;

    const getInternalReason = () => {
      if (!values.withdrawInternalReason) return;

      let reason = getWithdrawReason(values.withdrawInternalReason, intl);
      if (
        values.withdrawOption === WithdrawOption.MySalita &&
        values.withdrawInternalReason === ManagerJobWithdrawReason.InternalOther &&
        values.withdrawInternalOtherReason
      ) {
        reason = values.withdrawInternalOtherReason;
      }

      return reason;
    };

    const getSupplierReason = () => {
      if (!values.withdrawSupplierReason) return;

      let reason = getWithdrawReason(values.withdrawSupplierReason, intl);
      if (
        values.withdrawOption === WithdrawOption.Interpreter &&
        values.withdrawSupplierReason === ManagerJobWithdrawReason.SupplierOther &&
        values.withdrawSupplierOtherReason
      ) {
        reason = values.withdrawSupplierOtherReason;
      }

      return reason;
    };

    withdrawInterpreterMutation.mutate(
      {
        id: jobId,
        body: { supplierNote: getSupplierReason(), internalNote: getInternalReason() },
      },
      {
        onError: (error) => {
          showErrorToast(error);
        },
      },
    );
  };

  const validateForm = (values: WithdrawForm) => {
    if (
      !(values.withdrawOption && (values.withdrawSupplierReason || values.withdrawInternalReason))
    )
      return false;

    if (values.withdrawOption === WithdrawOption.MySalita) {
      if (
        values.withdrawInternalReason === ManagerJobWithdrawReason.InternalOther &&
        values.withdrawInternalOtherReason?.length
      ) {
        return true;
      } else if (values.withdrawInternalReason !== ManagerJobWithdrawReason.InternalOther) {
        return !!values.withdrawInternalReason;
      }
    }

    if (values.withdrawOption === WithdrawOption.Interpreter) {
      if (
        values.withdrawSupplierReason === ManagerJobWithdrawReason.SupplierOther &&
        values.withdrawSupplierOtherReason
      ) {
        return true;
      } else if (values.withdrawSupplierReason !== ManagerJobWithdrawReason.SupplierOther) {
        return !!values.withdrawSupplierReason;
      }
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: translationKeys.job_withdraw_modal_title })}
      description={intl.formatMessage({ id: translationKeys.job_withdraw_modal_description })}
      show={show}
      onHide={onClose}
      size="large"
    >
      <div className={styles.interpreterCardWrapper}>
        <InterpreterCard interpreter={interpreter} className={styles.interpreterCard} />
      </div>
      <Formik<WithdrawForm>
        initialValues={{
          withdrawOption: undefined,
          withdrawInternalReason: undefined,
          withdrawSupplierReason: undefined,
          withdrawInternalOtherReason: '',
          withdrawSupplierOtherReason: '',
        }}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const setFieldValue = getTypesafeSetFieldValue(formikProps);
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <RadioOptions
                label={intl.formatMessage({ id: translationKeys.job_withdraw_modal_who_withdraws })}
                options={withdrawOptions}
                onSelect={(selectedOption) => {
                  setFieldValue('withdrawOption', selectedOption as WithdrawOption);
                  setFieldValue('withdrawInternalReason', undefined);
                  setFieldValue('withdrawSupplierReason', undefined);
                }}
                selected={formikProps.values.withdrawOption}
              />

              {formikProps.values.withdrawOption === WithdrawOption.MySalita && (
                <div className={styles.content}>
                  <span>
                    <FormattedMessage id={translationKeys.job_withdraw_modal_withdraw_reasons} />
                  </span>
                  <div>
                    <div className={styles.radioOptions}>
                      <B_Form.Check
                        name="withdrawInternalReason"
                        type="radio"
                        label={getWithdrawReason(
                          ManagerJobWithdrawReason.InterpreterUsedInAnotherJob,
                          intl,
                        )}
                        value={ManagerJobWithdrawReason.InterpreterUsedInAnotherJob}
                        onChange={() =>
                          setFieldValue(
                            'withdrawInternalReason',
                            ManagerJobWithdrawReason.InterpreterUsedInAnotherJob,
                          )
                        }
                      />
                      <B_Form.Check
                        name="withdrawInternalReason"
                        type="radio"
                        label={getWithdrawReason(ManagerJobWithdrawReason.ConfirmedByMistake, intl)}
                        value={ManagerJobWithdrawReason.ConfirmedByMistake}
                        onChange={() =>
                          setFieldValue(
                            'withdrawInternalReason',
                            ManagerJobWithdrawReason.ConfirmedByMistake,
                          )
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="withdrawInternalReason"
                        label={getWithdrawReason(
                          ManagerJobWithdrawReason.DifferentInterpreter,
                          intl,
                        )}
                        value={ManagerJobWithdrawReason.DifferentInterpreter}
                        onChange={() =>
                          setFieldValue(
                            'withdrawInternalReason',
                            ManagerJobWithdrawReason.DifferentInterpreter,
                          )
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="withdrawInternalReason"
                        label={getWithdrawReason(ManagerJobWithdrawReason.InternalOther, intl)}
                        value={ManagerJobWithdrawReason.InternalOther}
                        onChange={() =>
                          setFieldValue(
                            'withdrawInternalReason',
                            ManagerJobWithdrawReason.InternalOther,
                          )
                        }
                      />
                    </div>
                    {formikProps.values.withdrawInternalReason ===
                      ManagerJobWithdrawReason.InternalOther && (
                      <TextField
                        name="withdrawInternalOtherReason"
                        value={formikProps.values.withdrawInternalOtherReason}
                        placeholder={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_reason_other_placeholder,
                        })}
                        className={styles.textArea}
                        textArea
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    )}
                  </div>
                </div>
              )}

              {formikProps.values.withdrawOption === WithdrawOption.Interpreter && (
                <div className={styles.content}>
                  <span>
                    <FormattedMessage id={translationKeys.job_withdraw_modal_withdraw_reasons} />
                  </span>
                  <div>
                    <div className={styles.radioOptions}>
                      <B_Form.Check
                        name="withdrawSupplierReason"
                        type="radio"
                        label={getWithdrawReason(ManagerJobWithdrawReason.DoubleBooked, intl)}
                        value={ManagerJobWithdrawReason.DoubleBooked}
                        onChange={() =>
                          setFieldValue(
                            'withdrawSupplierReason',
                            ManagerJobWithdrawReason.DoubleBooked,
                          )
                        }
                      />
                      <B_Form.Check
                        name="withdrawSupplierReason"
                        type="radio"
                        label={getWithdrawReason(ManagerJobWithdrawReason.Sick, intl)}
                        value={ManagerJobWithdrawReason.Sick}
                        onChange={() =>
                          setFieldValue('withdrawSupplierReason', ManagerJobWithdrawReason.Sick)
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="withdrawSupplierReason"
                        label={getWithdrawReason(ManagerJobWithdrawReason.SickKid, intl)}
                        value={ManagerJobWithdrawReason.SickKid}
                        onChange={() =>
                          setFieldValue('withdrawSupplierReason', ManagerJobWithdrawReason.SickKid)
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="withdrawSupplierReason"
                        label={getWithdrawReason(ManagerJobWithdrawReason.NotSuitable, intl)}
                        value={ManagerJobWithdrawReason.NotSuitable}
                        onChange={() =>
                          setFieldValue(
                            'withdrawSupplierReason',
                            ManagerJobWithdrawReason.NotSuitable,
                          )
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="withdrawSupplierReason"
                        label={getWithdrawReason(ManagerJobWithdrawReason.SupplierOther, intl)}
                        value={ManagerJobWithdrawReason.SupplierOther}
                        onChange={() =>
                          setFieldValue(
                            'withdrawSupplierReason',
                            ManagerJobWithdrawReason.SupplierOther,
                          )
                        }
                      />
                    </div>
                    {formikProps.values.withdrawSupplierReason ===
                      ManagerJobWithdrawReason.SupplierOther && (
                      <TextField
                        name="withdrawSupplierOtherReason"
                        value={formikProps.values.withdrawSupplierOtherReason}
                        placeholder={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_reason_other_placeholder,
                        })}
                        className={styles.textArea}
                        textArea
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className={styles.actionButtons}>
                <Button
                  onClick={onClose}
                  variant="gray"
                  size="large"
                  className={styles.cancelButton}
                  data-testid="cancel-button"
                >
                  <FormattedMessage id={translationKeys.job_withdraw_modal_cancel} />
                </Button>

                <Button
                  iconPosition="left"
                  size="large"
                  variant="danger"
                  type="submit"
                  data-testid="submit-button"
                  disabled={!validateForm(formikProps.values)}
                >
                  <FormattedMessage id={translationKeys.job_withdraw_modal_withdraw_button} />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
