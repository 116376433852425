import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DatePicker, SearchDropdown, TextField, TimePicker } from '@skiwo/components';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobBookingMechanism, ManagerJobSessionType } from '../../../types';
import { getBookingMechanismOptions } from '../../utils/getBookingMechanismOptions';
import { getFeesOptions, ManagerJobBookingFee } from '../../utils/getFeesOptions';
import { getSessionTypeOptions } from '../../utils/getSessionTypeOptions';
import { StatementFormValues } from './Statement';
import styles from './Statement.module.scss';

interface StatementSettingsProps {
  readonly?: boolean;
}

export const StatementSettings = ({ readonly }: StatementSettingsProps) => {
  const intl = useIntl();
  const [activeDatePicker, setActiveDatePicker] = useState(false);
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <div className={styles.settingsRow}>
      <div className={styles.settings}>
        <SearchDropdown
          label={intl.formatMessage({ id: translationKeys.job_statement_charge_as })}
          options={getSessionTypeOptions(intl)}
          placeholder={intl.formatMessage({ id: translationKeys.job_statement_charge_as })}
          selectedKeys={values.chargeAs ? [values.chargeAs] : []}
          onChange={(options) =>
            !!options?.length && setFieldValue('chargeAs', options[0].key as ManagerJobSessionType)
          }
          hideClearSelected
          disabled={readonly}
        />

        <SearchDropdown
          label={intl.formatMessage({ id: translationKeys.job_statement_other_method })}
          options={getBookingMechanismOptions(intl)}
          placeholder={intl.formatMessage({ id: translationKeys.job_statement_other_method })}
          selectedKeys={values.orderMethod ? [values.orderMethod] : []}
          onChange={(options) =>
            !!options?.length &&
            setFieldValue('orderMethod', options[0].key as ManagerJobBookingMechanism)
          }
          hideClearSelected
          disabled={readonly}
        />
        <div className={styles.date}>
          <div>
            <TextField
              label={intl.formatMessage({ id: translationKeys.job_statement_ordered })}
              placeholder={intl.formatMessage({
                id: translationKeys.job_statement_ordered_placeholder,
              })}
              data-testid="state-date-filter"
              onFocus={() => setActiveDatePicker(true)}
              value={values.orderedDate && format(values.orderedDate, 'dd.MM.yyyy')}
              name="orderedDate"
              type="search"
              readOnly
              disabled={readonly}
              icon={<FontAwesomeIcon icon={faCalendar} />}
            />
            {activeDatePicker && (
              <DatePicker
                onClose={() => setActiveDatePicker(false)}
                onChange={(date) => {
                  if (date) {
                    setFieldValue('orderedDate', date);
                  }
                  setActiveDatePicker(false);
                }}
                selected={values.orderedDate}
                singleDate
                monthCount={1}
              />
            )}
          </div>
          <TimePicker
            size="medium"
            placeholder="00:00"
            selected={values.orderedTime && [values.orderedTime]}
            onChange={(item) => {
              if (item && item.length > 0) {
                setFieldValue('orderedTime', item[0]);
              }
            }}
            disabled={readonly}
          />
        </div>
        <SearchDropdown
          label={intl.formatMessage({
            id: translationKeys.job_statement_fees,
          })}
          options={getFeesOptions(intl)}
          selectedKeys={values.fees}
          placeholder={intl.formatMessage({
            id: translationKeys.job_statement_fees,
          })}
          multiple
          onChange={(options) =>
            options?.length
              ? setFieldValue(
                  'fees',
                  options.map((option) => option.key as ManagerJobBookingFee),
                )
              : setFieldValue('fees', [])
          }
          hideClearSelected
          disabled={readonly}
        />
      </div>
      {/* TODO: Create ticket */}
      <Button variant="gray" size="medium" disabled>
        <FormattedMessage id={translationKeys.job_statement_create_ticket} />
      </Button>
    </div>
  );
};

export default StatementSettings;
