import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { faCalendar, faFileInvoice } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from '@skiwo/components';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { useGetCustomerDetailsQuery } from '../../Api/Endpoints/Customers/Customers.hooks';
import { jobQueryKeys, useGetJobQuery } from '../../Api/Endpoints/Jobs/Jobs.hooks';
import { getAssignmentTypeIcon } from '../../CreateInterpretationOrder/utils';
import { getPaymentMethodName } from '../../helpers/getPaymentMethodName';
import { getSeparatedValues } from '../../helpers/getSeparatedValues';
import routes from '../../helpers/routes';
import { getJobLanguageToId } from '../../Jobs/utils/getJobToLanguage';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import useGetJobIdFromParam from '../hooks/useGetJobIdFromParam';
import BookerHoverCard from './BookerHoverCard/BookerHoverCard';
import InfoItem from './InfoItem/InfoItem';
import styles from './JobDetails.module.scss';

export const InfoSection = () => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const queryClient = useQueryClient();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const { data: customer } = useGetCustomerDetailsQuery({
    customerUid: job?.owner?.person?.uid,
  });

  if (!job) return null;

  const handleResetJob = () =>
    queryClient.invalidateQueries({
      queryKey: jobQueryKeys.all,
    });

  const personName = job.info.realPersonName ?? job.owner?.person?.name;

  return (
    <>
      <div className={styles.mainInfo}>
        <span className={classNames(styles.flexRow, styles.date)}>
          {format(new Date(job.actualCreatedAt), 'dd.MM.yyyy HH:mm')}

          {job.originalJobId && (
            <span>
              {' • '}
              {intl.formatMessage(
                {
                  id: translationKeys.job_copied_from_label,
                },
                {
                  orderId: (
                    <Link className={styles.link} to={`${routes.jobs}/${job.originalJobId}`}>
                      {job.originalJobId}
                    </Link>
                  ),
                },
              )}
            </span>
          )}
        </span>
        <div className={classNames(styles.flexRow, styles.header)}>
          <span>{job.id}</span>
          <FontAwesomeIcon
            icon={getAssignmentTypeIcon(job.interpretationRequirement.sessionType)}
            size="xs"
          />
          <span>
            {getLanguageById(
              getJobLanguageToId({
                alternativeLangToId:
                  job.interpretationRequirement.alternativeLanguageToId || undefined,
                alternativeLanguageActivated: job.processRequirement.alternativeLanguageActivated,
                alternativeLanguageProcessing: job.processRequirement.alternativeLanguageProcessing,
                langToId: job.interpretationRequirement.languageToId,
              }),
            )}
          </span>
        </div>
        <span className={styles.category}>
          {getSeparatedValues([
            getSeparatedValues([job.info.jobCategoryName, job.info.jobCategoryItemName], ' - '),
            job.info.subject,
          ])}
        </span>
      </div>
      <div className={styles.infoItems}>
        <InfoItem
          label={intl.formatMessage({ id: translationKeys.job_date_and_time_label })}
          icons={<FontAwesomeIcon icon={faCalendar} />}
        >
          {format(new Date(job.interpretationRequirement.startTime), 'dd.MM.yyyy HH:mm')}
          {' - '}
          {format(new Date(job.interpretationRequirement.finishTime), 'HH:mm')}
          <Tag variant="square" color="neutral" size="small">
            <span className={styles.timeInterval}>
              {formatDuration(
                intervalToDuration({
                  start: new Date(job.interpretationRequirement.startTime),
                  end: new Date(job.interpretationRequirement.finishTime),
                }),
                {
                  format: ['hours', 'minutes'],
                  locale: {
                    formatDistance: (token: 'xHours' | 'xMinutes', count: number) => {
                      const formatMap = {
                        xHours: `${count}h`,
                        xMinutes: `${count}m`,
                      };
                      return formatMap[token];
                    },
                  },
                },
              )}
            </span>
          </Tag>
        </InfoItem>
        <InfoItem
          label={intl.formatMessage({ id: translationKeys.job_confirm_by_label })}
          icons={<FontAwesomeIcon icon={faCalendar} />}
        >
          {job.interpretationRequirement.confirmationBy &&
            format(new Date(job.interpretationRequirement.confirmationBy), 'dd.MM.yyyy HH:mm')}
        </InfoItem>
        <BookerHoverCard customerUid={customer?.uid} onComplete={handleResetJob}>
          <InfoItem label={intl.formatMessage({ id: translationKeys.job_booker_label })} hasHover>
            {`${personName}, ${job.enterprise?.name}`}
          </InfoItem>
        </BookerHoverCard>
        <InfoItem
          label={intl.formatMessage({ id: translationKeys.job_payment_method_label })}
          icons={<FontAwesomeIcon icon={faFileInvoice} />}
        >
          {getPaymentMethodName(
            {
              orgName: job.paymentMethod.orgName,
              orgNumber: job.paymentMethod.orgNumber,
              email: job.paymentMethod.email,
            },
            intl,
          )}
        </InfoItem>
      </div>
    </>
  );
};

export default InfoSection;
