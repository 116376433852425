import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, TextField, TimePicker } from '@skiwo/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { addTimeToDate, getTimeOption } from '../../CreateInterpretationOrder/utils';
import getRequiredMessage from '../../helpers/getRequiredFieldMessage';
import translationKeys from '../../translations/translationKeys';
import styles from './DateTimePicker.module.scss';

interface IDateTimePickerProps {
  date?: Date;
  setDate: (date: Date) => void;
  translations?: {
    dateLabel?: string;
    datePlaceholder?: string;
    timeLabel?: string;
  };
  isInvalid?: boolean;
  className?: string;
}

export const DateTimePicker = ({
  date,
  setDate,
  translations,
  isInvalid,
  className,
}: IDateTimePickerProps) => {
  const intl = useIntl();
  const [showDeadlineDatePicker, setShowDeadlineDatePicker] = useState(false);

  return (
    <div>
      <div className={classNames(styles.flexRow, className)}>
        <div>
          <TextField
            data-testid="confirmation-by-date"
            label={
              translations?.dateLabel ??
              intl.formatMessage({
                id: translationKeys.global_date_time_picker_date_label,
              })
            }
            placeholder={
              translations?.datePlaceholder ??
              intl.formatMessage({
                id: translationKeys.global_date_time_picker_date_placeholder,
              })
            }
            icon={<FontAwesomeIcon icon={faCalendar} />}
            value={date && format(date, 'dd.MM.yyyy')}
            size="large"
            onFocus={() => setShowDeadlineDatePicker(true)}
            type="search"
            readOnly
            isInvalid={isInvalid}
          />
          {showDeadlineDatePicker && (
            <DatePicker
              monthCount={1}
              onClose={() => setShowDeadlineDatePicker(false)}
              singleDate
              onChange={(date) => {
                setDate(date);
                setShowDeadlineDatePicker(false);
              }}
              selected={date}
              minDate={new Date()}
            />
          )}
        </div>

        <div className={styles.timePicker}>
          <TimePicker
            data-testid="confirmation-by-time"
            label={
              translations?.timeLabel ??
              intl.formatMessage({
                id: translationKeys.global_date_time_picker_time_label,
              })
            }
            placeholder="00:00"
            interval={1}
            selected={date && [getTimeOption(date)]}
            onChange={(item) => {
              if (item && item.length > 0) {
                date && setDate(addTimeToDate(date, item[0]));
              }
            }}
            isInvalid={isInvalid}
          />
        </div>
      </div>
      {isInvalid && (
        <span className={styles.errorMessage} data-testid="timepicker-error">
          <span className={styles.errorMessageIcon}>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          {getRequiredMessage(intl)}
        </span>
      )}
    </div>
  );
};
