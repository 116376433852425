import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Drawer, Pagination } from '@skiwo/components';
import { format } from 'date-fns';
import SystemLogLoader from '../../../../components/SystemLog/SystemLogLoader';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import SystemLog, {
  SystemLogActionType,
  SystemLogActorRole,
} from '../../../../types/ManagerOrderSystemLog';
import { getValuesByType } from './helpers/getValuesByType';
import styles from './SystemLogDrawer.module.scss';

interface Props {
  show?: boolean;
  orderId: number;
  onClose: () => void;
}

const SystemLogDrawer = (props: Props) => {
  const { show, orderId, onClose } = props;
  const [logsLoading, setLogsLoading] = useState(false);
  const [logs, setLogs] = useState<SystemLog[] | undefined>();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [logCount, setLogCount] = useState<number | undefined>();
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();

  const getTextForLog = (logItem: SystemLog) => {
    let name = logItem.actor?.name || logItem.adminActor?.name || 'Unknown';

    let textKey = `${logItem.logType} was performed`;

    let updatedElementKey = `${logItem.logType}`;

    if (logItem.loggedActionType === SystemLogActionType.notification) {
      textKey = `${logItem.logType} was sent`;
      name = logItem.recipient?.name || 'Unknown';
    }

    let key = `translation_order_system_logs_${logItem.logType}`;

    if (logItem.logType.includes('order_updated')) {
      key = `translation_order_system_logs_order_updated_sentence`;
    }
    if (logItem.logType.includes('task_updated')) {
      key = `translation_order_system_logs_task_updated_sentence`;
    }
    if (logItem.logType.includes('subtask_updated')) {
      key = `translation_order_system_logs_subtask_updated_sentence`;
    }
    if (translationKeys[`translation_order_system_logs_${logItem.logType}`]) {
      updatedElementKey = `translation_order_system_logs_${logItem.logType}`;
    }

    if (Object.keys(translationKeys).includes(key)) {
      textKey = translationKeys[key as keyof typeof translationKeys];
    }
    if (logItem.actorRole === SystemLogActorRole.System) {
      name = intl.formatMessage({ id: translationKeys.translation_order_system_logs_system_label });
    }

    return intl.formatMessage(
      {
        id: textKey,
      },
      {
        name: <strong>{name}</strong>,
        task: <strong>{logItem.context}</strong>,
        subtask: <strong>{logItem.context}</strong>,
        updated_element: intl.formatMessage({ id: updatedElementKey }),
      },
    );
  };

  const getActorAvatar = (logItem: SystemLog) => {
    if (logItem.actorRole === SystemLogActorRole.System) {
      return (
        <div className={styles.systemIcon}>
          <FontAwesomeIcon icon={faGear} />
        </div>
      );
    }

    const avatarName = logItem.actor?.name || logItem.adminActor?.name || 'unknown';
    const avatarUrl = logItem.actor?.avatar;

    return <Avatar altText={avatarName} url={avatarUrl} size="medium" />;
  };

  const getSystemLogs = async (page = 1, loadMoreTriggered?: boolean) => {
    const params = {
      page: page,
      items: 10,
    };

    const { data, error } = await api.getOrderSystemLogs(
      orderId.toString(),
      params,
      setLogsLoading,
    );

    if (data) {
      if (page > 1 && logs && loadMoreTriggered) {
        const updatedLogs = [...logs, ...data.collection];
        setLogs(updatedLogs);
      } else {
        setLogs(data.collection);
      }

      setLogCount(data.pagination.count);
      setPage(data.pagination.page);
      setPages(data.pagination.pages);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const handleLoadMore = () => {
    getSystemLogs(page + 1, true);
  };

  useEffect(() => {
    if (!show) return;

    getSystemLogs();
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_order_system_logs_title })}
      data-testid="system-log-drawer"
    >
      <div className={styles.systemLog}>
        {logCount && (
          <span className={styles.headerInfo}>
            <FormattedMessage
              id={translationKeys.translation_order_system_logs_item_count}
              values={{
                count: logCount,
              }}
            />
          </span>
        )}

        {logsLoading && !logs && <SystemLogLoader />}

        {logs &&
          logs.map((logItem, index) => {
            const createdAt = new Date(logItem.createdAt);
            return (
              <div key={index} className={styles.logItem}>
                <div className={styles.avatar}>{getActorAvatar(logItem)}</div>

                <div>
                  <div className={styles.logSubject}>
                    <span>{getTextForLog(logItem)}</span>
                  </div>

                  <span className={styles.date}>{format(createdAt, 'dd.MM.yyyy HH:mm')}</span>

                  {getValuesByType(logItem, intl)}
                </div>
              </div>
            );
          })}
      </div>

      {page < pages && (
        <div className={styles.loadMoreButton}>
          <Button size="large" variant="secondary" isLoading={logsLoading} onClick={handleLoadMore}>
            <FormattedMessage id={translationKeys.translation_order_system_logs_load_more_button} />
          </Button>
        </div>
      )}

      <div className={styles.pagination}>
        <Pagination
          currentPage={page}
          totalPages={pages}
          setPage={(page) => getSystemLogs(page)}
        />
      </div>
    </Drawer>
  );
};

export default SystemLogDrawer;
