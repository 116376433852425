import React from 'react';
import { Table as B_Table, TableProps } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './Table.module.scss';

interface Props {
  children: React.ReactNode;
  hoverable?: boolean;
}

const Table = ({ children, hoverable = true, ...tableProps }: Props & TableProps) => {
  return (
    <B_Table {...tableProps} className={classNames(styles.table, hoverable && styles.hoverable)}>
      {children}
    </B_Table>
  );
};

export default Table;
