import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faExclamationCircle, faGraduationCap, faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tag, Tooltip } from '@skiwo/components';
import classNames from 'classnames';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { useQualifications } from '../../../providers/QualificationsProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobDowngradeRequirement,
  ManagerJobQualificationProcessing,
  ManagerJobStatus,
} from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import { jobSectionVisibilityGuard } from '../../utils/jobSectionVisibilityGuard';
import RequirementCard from './RequirementCard/RequirementCard';
import styles from './RequirementsSection.module.scss';

interface RequirementsQualificationCardProps {
  downgradeRequirement: (requirement: ManagerJobDowngradeRequirement) => void;
}

export const RequirementsQualificationCard = ({
  downgradeRequirement,
}: RequirementsQualificationCardProps) => {
  const intl = useIntl();
  const { getQualificationById } = useQualifications();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const [showModal, setShowModal] = useState(false);

  if (!job) return null;

  const actionsRowVisible = jobSectionVisibilityGuard({
    currentStatus: job.status,
    statuses: [ManagerJobStatus.Published, ManagerJobStatus.Applied, ManagerJobStatus.Accepted],
  });

  const qualification = getQualificationById(job.interpretationRequirement.qualificationId);

  const getQualificationValue = () => {
    if (qualification?.isInTolkeregister && !job.processRequirement.qualificationAutoUpdated) {
      return (
        <div className={styles.flexRow}>
          <span>
            <FormattedMessage id={translationKeys.job_requirements_card_qualification_category} />
          </span>
          <Tag variant="square" color={'success'} size="small">
            {qualification.tolkeregisterName}
          </Tag>
        </div>
      );
    } else {
      return (
        <span>
          <FormattedMessage id={translationKeys.job_requirements_card_qualification_all} />
        </span>
      );
    }
  };

  return (
    <>
      <RequirementCard
        label={intl.formatMessage({
          id: translationKeys.job_requirements_card_qualification_label,
        })}
        icons={<FontAwesomeIcon icon={faGraduationCap} />}
        value={getQualificationValue()}
      >
        {actionsRowVisible &&
        job.processRequirement.qualificationProcessing ===
          ManagerJobQualificationProcessing.qualification_auto_update &&
        !job.processRequirement.qualificationAutoUpdated ? (
          <div className={classNames(styles.flexRow, styles.spaceBetween)}>
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.job_requirements_card_qualification_tooltip,
              })}
            >
              <div className={styles.flexRow}>
                <FontAwesomeIcon className={styles.clockIcon} icon={faExclamationCircle} />
                <span>
                  <FormattedMessage
                    id={translationKeys.job_requirements_card_reset_if_no_interpreter}
                  />
                </span>
              </div>
            </Tooltip>
            <Button
              variant="link"
              icon={<FontAwesomeIcon icon={faRotate} />}
              className={styles.resetButton}
              onClick={() => setShowModal(true)}
            >
              <FormattedMessage id={translationKeys.job_requirements_card_reset} />
            </Button>
          </div>
        ) : null}
        {actionsRowVisible &&
        job.processRequirement.qualificationProcessing ===
          ManagerJobQualificationProcessing.qualification_cancel_not_found &&
        !job.processRequirement.qualificationAutoUpdated ? (
          <div className={classNames(styles.flexRow, styles.spaceBetween)}>
            <div className={styles.flexRow}>
              <FontAwesomeIcon className={styles.exclamationMarkIcon} icon={faExclamationCircle} />
              <span>
                <FormattedMessage
                  id={translationKeys.job_requirements_card_cancel_if_no_interpreter}
                />
              </span>
            </div>
            <Button
              variant="link"
              icon={<FontAwesomeIcon icon={faRotate} />}
              className={styles.resetButton}
              onClick={() => setShowModal(true)}
            >
              <FormattedMessage id={translationKeys.job_requirements_card_reset} />
            </Button>
          </div>
        ) : null}
      </RequirementCard>
      <Modal
        show={showModal}
        title={intl.formatMessage({
          id: translationKeys.job_requirements_card_reset_qualification,
        })}
        description={intl.formatMessage({
          id: translationKeys.job_requirements_card_reset_qualification_description,
        })}
        onCancel={() => setShowModal(false)}
        onHide={() => setShowModal(false)}
        submitButtonText={intl.formatMessage({ id: translationKeys.job_requirements_card_confirm })}
        onSubmit={() => {
          downgradeRequirement(ManagerJobDowngradeRequirement.Qualification);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default RequirementsQualificationCard;
