import React from 'react';
import { useIntl } from 'react-intl';
import { faEnvelope, faLocationDot, faPhone, faVenusMars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Tag, Tooltip } from '@skiwo/components';
import classNames from 'classnames';
import getGenderName from '../../helpers/getGenderName';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useQualifications } from '../../providers/QualificationsProvider';
import { ManagerJobAcceptedOrInvitedInterpreter } from '../../types/ManagerJob';
import styles from './InterpreterCard.module.scss';

interface InterpreterCardProps {
  interpreter?: ManagerJobAcceptedOrInvitedInterpreter;
  actions?: React.ReactNode;
  statusTags?: React.ReactNode;
  className?: string;
}

const InterpreterCard = ({ interpreter, actions, statusTags, className }: InterpreterCardProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const { getQualificationById } = useQualifications();

  if (!interpreter) return null;

  const getAddress = () => {
    const addressItems = [];

    if (interpreter.city) {
      addressItems.push(interpreter.city);
    }
    if (interpreter.country) {
      addressItems.push(interpreter.country);
    }

    return addressItems.join(', ');
  };

  const subtitleItems = [
    {
      icon: <FontAwesomeIcon icon={faLocationDot} />,
      value: getAddress(),
    },
    // V2
    // { icon: <FontAwesomeIcon icon={faArrowsLeftRight} />, value: '75.67 km' },
    {
      icon: <FontAwesomeIcon icon={faVenusMars} />,
      value: getGenderName(interpreter.person.gender, intl),
    },
  ];

  const mainItems = interpreter.skills.map((skill) => {
    const qualification = getQualificationById(skill.qualificationId);
    return {
      icon: (
        <Tag
          variant="square"
          color={qualification?.isInTolkeregister ? 'success' : 'neutral'}
          size="small"
        >
          {qualification?.isInTolkeregister && qualification.tolkeregisterName
            ? qualification.tolkeregisterName
            : 'N/A'}
        </Tag>
      ),
      value: getLanguageById(skill.languageToId),
    };
  });

  return (
    <>
      <div className={classNames(styles.interpreterCard, className)} data-testid="details-card">
        <div className={styles.header}>
          <Avatar
            altText={interpreter.person.name || 'logo'}
            url={interpreter.person.avatar}
            size="large"
          />

          <div className={styles.headerContent}>
            <div className={styles.detailsTitle}>
              <Tooltip title={interpreter.person.phone} copyable>
                <FontAwesomeIcon icon={faPhone} size="xs" />
              </Tooltip>
              <Tooltip title={interpreter.person.email} copyable>
                <FontAwesomeIcon icon={faEnvelope} size="xs" />
              </Tooltip>
              <span data-testid="details-card-title">{interpreter.person.name}</span>
              {statusTags}
            </div>

            <div className={styles.headerValues}>
              {subtitleItems.map((item, i) => {
                return (
                  item.value && (
                    <span key={i}>
                      {item.icon}
                      {item.value}
                    </span>
                  )
                );
              })}
            </div>
          </div>
          {actions}
        </div>

        {!!mainItems.filter((item) => item.value !== undefined).length && (
          <>
            <hr className={styles.separator} data-testid="separator" />
            <div className={styles.footer}>
              {mainItems.map((item, i) => {
                return (
                  item.value && (
                    <div key={i}>
                      <span className={styles.value}>
                        {item.icon}
                        {item.value}
                      </span>
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InterpreterCard;
