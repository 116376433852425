import { SearchInterpretersResponse } from '../../../types/Interpreter';
import { InterpreterAvailability } from '../../../types/InterpreterAvailability';
import fetchApi, { UrlParams } from '../../fetchApi';

export default {
  searchInterpreters: (query: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<SearchInterpretersResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/interpreters/search`,
      urlParams: query,
      loadingCallback,
    });
  },

  getInterpreterAvailability: (
    interpreterUid: string,
    query: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<InterpreterAvailability>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/interpreters/${interpreterUid}/availability`,
      urlParams: query,
      loadingCallback,
    });
  },
};
