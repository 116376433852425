import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faMagicWandSparkles, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@skiwo/components';
import {
  AvailabilityTimelineMeeting,
  AvailabilityTimelineVariant,
} from '@skiwo/components/src/AvailabilityTimeline/AvailabilityTimeline';
import { differenceInCalendarDays } from 'date-fns';
import { useGetSuitableInterpretersQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import {
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
} from '../../../CreateInterpretationOrder/utils';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobSessionType } from '../../../types';
import {
  InterpreterAvailability,
  InterpreterAvailabilityEventSessionType,
  InterpreterAvailabilityEventType,
} from '../../../types/InterpreterAvailability';
import { ManagerJobSuitableInterpreter } from '../../../types/ManagerJobSuitableInterpreter';
import { getHoursArray } from './helpers/InterpretersTimeline.helpers';
import useScrollTimeslotIntoView from './helpers/useScrollTimeslotIntoView';
import useSyncTimelineWithScroll from './helpers/useSyncTimelineWithScroll';
import PersonCellSkeleton from './PersonCellSkeleton/PersonCellSkeleton';
import getInterpretersTimelineFilters from './getInterpretersTimelineFilters';
import {
  InterpretersTimelineFilterField,
  InterpretersTimelineFilters,
} from './InterpretersTimelineFilters';
import PersonCell from './PersonCell';
import TimeslotPill from './TimeslotPill';
import styles from './InterpretersTimeline.module.scss';

type Filters = Record<string, string | string[] | undefined>;

type Meeting = {
  start: Date;
  end: Date;
  variant?: 'selected' | 'green' | 'gray' | 'default';
  icon?: JSX.Element;
  title?: string;
};

export interface InterpretersTimelineProps {
  jobId: string;
  sessionType: ManagerJobSessionType;
  selectedTimeslot: Omit<Meeting, 'variant'>;
}

export default function InterpretersTimeline({
  jobId,
  sessionType,
  selectedTimeslot,
}: InterpretersTimelineProps) {
  const intl = useIntl();
  const [interpreters, setInterpreters] = useState<ManagerJobSuitableInterpreter[]>([]);
  const selectedTimeslotDays = differenceInCalendarDays(
    selectedTimeslot.end,
    selectedTimeslot.start,
  );

  const gridHours = 24 + selectedTimeslotDays * 24;
  const hoursArr = getHoursArray(gridHours);
  const [cellWidth, setCellWidth] = useState(0);
  const { scrollableRef, timelineAxisRef } = useSyncTimelineWithScroll();
  const selectedTimeslotRef = useScrollTimeslotIntoView(interpreters);

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filters>({});

  const { data: interpretersData, isLoading } = useGetSuitableInterpretersQuery({
    id: jobId,
    urlParams: {
      ...filters,
      page: {
        number: page,
        size: 6,
      },
    },
  });

  const getItemPosition = (start: Date, end: Date) => {
    if (!cellWidth) return { left: 0, width: 0 };

    const calculatePosition = (date: Date) => {
      const dayOffset = (date.getDate() - selectedTimeslot.start.getDate()) * 24 * cellWidth;
      const hourOffset = date.getHours() * cellWidth;
      const minuteOffset = date.getMinutes() * (cellWidth / 60);
      return dayOffset + hourOffset + minuteOffset;
    };

    const startsDayBeforeAssignment = differenceInCalendarDays(start, selectedTimeslot.start) < 0;
    const finishAtDifferentDayThanAssignment =
      differenceInCalendarDays(end, selectedTimeslot.end) > 0;

    let startPos = calculatePosition(start);
    let endPos = calculatePosition(end);

    if (startsDayBeforeAssignment) {
      const startOfDay = new Date(selectedTimeslot.start);
      startOfDay.setHours(0, 0, 0, 0);
      startPos = calculatePosition(startOfDay);
    }

    if (finishAtDifferentDayThanAssignment) {
      const endOfDay = new Date(selectedTimeslot.end);
      endOfDay.setHours(23, 59, 59, 999);
      endPos = calculatePosition(endOfDay);
    }

    return {
      left: startPos,
      width: endPos - startPos,
    };
  };

  const handleFilterChange = (field: InterpretersTimelineFilterField, value: string | string[]) => {
    const newFilters = getInterpretersTimelineFilters(filters, field, value);
    setPage(1);
    setFilters(newFilters);
  };

  const serializeMeetings = (data: InterpreterAvailability) => {
    const timeslotStart = new Date(selectedTimeslot.start);
    const timeslotEnd = new Date(selectedTimeslot.end);

    const isMeetingWithinTimeslot = (startTime: Date, endTime: Date) => {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      return (
        differenceInCalendarDays(startDate, timeslotEnd) <= 0 &&
        differenceInCalendarDays(endDate, timeslotStart) >= 0
      );
    };

    const createMeeting = (
      title: string,
      start: Date,
      end: Date,
      variant: AvailabilityTimelineVariant,
      icon?: JSX.Element,
    ): AvailabilityTimelineMeeting => ({
      title,
      start: new Date(start),
      end: new Date(end),
      variant,
      icon,
    });

    const jobMeetings = data.jobs.flatMap((job) =>
      isMeetingWithinTimeslot(job.startTime, job.finishTime)
        ? [
            createMeeting(
              getAssignmentTypeLabel(job.sessionType, intl),
              job.startTime,
              job.finishTime,
              'default',
              <FontAwesomeIcon icon={getAssignmentTypeIcon(job.sessionType)} />,
            ),
          ]
        : [],
    );

    const eventMeetings = data.events.flatMap((event) => {
      if (!isMeetingWithinTimeslot(event.startTime, event.finishTime)) return [];

      if (event.eventType === InterpreterAvailabilityEventType.StandByTime) {
        const showStandbyBlock =
          (event.sessionType === InterpreterAvailabilityEventSessionType.Remote &&
            sessionType !== ManagerJobSessionType.InPerson) ||
          (event.sessionType === InterpreterAvailabilityEventSessionType.OnSite &&
            sessionType === ManagerJobSessionType.InPerson);

        return showStandbyBlock
          ? [
              createMeeting(
                intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_interpreter_standby_block,
                }),
                event.startTime,
                event.finishTime,
                'green',
                <FontAwesomeIcon icon={faMagicWandSparkles} />,
              ),
            ]
          : [];
      }

      return [
        createMeeting(
          'N/A',
          event.startTime,
          event.finishTime,
          'default',
          <FontAwesomeIcon icon={faXmarkCircle} />,
        ),
      ];
    });

    const offTimeMeetings = data.offTimes.flatMap((offTime) =>
      isMeetingWithinTimeslot(offTime.startTime, offTime.finishTime)
        ? [
            createMeeting(
              'N/A',
              offTime.startTime,
              offTime.finishTime,
              'default',
              <FontAwesomeIcon icon={faXmarkCircle} />,
            ),
          ]
        : [],
    );

    const travelMeetings = data.travelTimes.flatMap((travelTime) =>
      isMeetingWithinTimeslot(travelTime.startTime, travelTime.finishTime)
        ? [createMeeting('N/A', travelTime.startTime, travelTime.finishTime, 'gray')]
        : [],
    );

    return [
      ...eventMeetings.filter((meeting) => meeting.variant === 'green'),
      ...eventMeetings.filter((meeting) => meeting.variant !== 'green'),
      ...jobMeetings,
      ...offTimeMeetings,
      ...travelMeetings,
    ];
  };

  useEffect(() => {
    if (!interpretersData) return;

    const appendData = interpretersData.pagination.page > 1;
    setInterpreters(
      appendData
        ? (prev) => [...prev, ...interpretersData.collection]
        : interpretersData.collection,
    );
  }, [interpretersData]);

  return (
    <div className={styles.interpretersTimeline}>
      <InterpretersTimelineFilters filters={filters} onFilterChange={handleFilterChange} />
      <div className={styles.calendarWrapper}>
        <div className={styles.twoColGrid}>
          <div className={styles.timelineOverflow} />
          <div
            ref={timelineAxisRef}
            className={styles.timeGrid}
            style={{ gridTemplateColumns: `repeat(${gridHours + 1}, 10rem)` }}
          >
            {hoursArr.map((hour) => (
              <div key={hour} className={styles.timeCell}>
                {hour}
              </div>
            ))}
            <div ref={(ref) => setCellWidth(ref?.offsetWidth ?? 0)} className={styles.timeCell}>
              {hoursArr[0]}
            </div>
          </div>
        </div>
        <div className={styles.twoColGrid}>
          <div>
            {interpreters.map((interpreter) => (
              <div key={interpreter.person.uid} className={styles.timelineCell}>
                <PersonCell interpreter={interpreter} />
              </div>
            ))}
            {!interpreters.length &&
              [...Array(3)].map((_, index) => <PersonCellSkeleton key={index} />)}
          </div>
          <div ref={scrollableRef} className={styles.scrollableCol}>
            {interpreters.map((interpreter, i) => {
              const meetings = serializeMeetings(interpreter.availability);
              return (
                <div
                  key={`${interpreter.person.uid}_data_row_${i}`}
                  className={styles.timelineGrid}
                  style={{ gridTemplateColumns: `repeat(${gridHours + 1}, 10rem)` }}
                >
                  {meetings.map((meeting) => {
                    return (
                      <TimeslotPill
                        key={`${meeting.start.valueOf()}_${meeting.end.valueOf()}_${
                          interpreter.person
                        }`}
                        variant={meeting.variant || 'default'}
                        position={getItemPosition(meeting.start, meeting.end)}
                        icon={meeting.icon}
                        title={meeting.title}
                      />
                    );
                  })}
                  {hoursArr.map((hour) => (
                    <div
                      key={`${hour}_${interpreter.person.uid}`}
                      className={styles.timelineCell}
                    />
                  ))}
                </div>
              );
            })}
            {!interpreters.length &&
              [...Array(3)].map((_, index) => (
                <div
                  className={styles.timelineGrid}
                  style={{ gridTemplateColumns: `repeat(${gridHours}, 10rem)` }}
                  key={index}
                >
                  {hoursArr.map((hour) => (
                    <div key={`${hour}}`} className={styles.timelineCell} />
                  ))}
                </div>
              ))}
            {selectedTimeslot && !isLoading && !!interpreters.length && (
              <TimeslotPill
                ref={selectedTimeslotRef}
                position={getItemPosition(selectedTimeslot.start, selectedTimeslot.end)}
                variant="selected"
                {...selectedTimeslot}
              />
            )}
          </div>
        </div>
        {!interpreters.length && !isLoading && (
          <div className={styles.emptyState}>No interpreters found</div>
        )}
      </div>
      {interpretersData && page < interpretersData.pagination.pages && (
        <div className={styles.loadMoreButton}>
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              setPage(page + 1);
            }}
            data-testid="load-more-button"
          >
            <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
          </Button>
        </div>
      )}
    </div>
  );
}
