import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  faBan,
  faCircleXmark,
  faMagicWandSparkles,
  faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, IconButton } from '@skiwo/components';
import classNames from 'classnames';
import {
  useDeclineInterpreterMutation,
  useGetJobQuery,
  useInviteInterpreterMutation,
} from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import circleUnblockIcon from '../../../assets/icons/circle-unblock.svg';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobSuitableInterpreter,
  ManagerJobSuitableInterpreterDiscussionStatus,
  ManagerJobSuitableInterpreterStatus,
} from '../../../types/ManagerJobSuitableInterpreter';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import { ApplyInterpreterDrawer } from './ApplyInterpreterDrawer/ApplyInterpreterDrawer';
import AwardInterpreterModal from './AwardInterpreterModal/AwardInterpreterModal';
import BlockedInterpreterStatusModal from './BlockedInterpreterStatusModal/BlockedInterpreterStatusModal';
import { getErrorBannerText, getWarningBannerText } from './helpers/InterpretersTimeline.helpers';
import PersonDetails from './PersonDetails';
import styles from './InterpretersTimeline.module.scss';

export default function PersonCell({
  interpreter,
}: {
  interpreter: ManagerJobSuitableInterpreter;
}) {
  const intl = useIntl();
  const [isActionSectionVisible, setIsActionSectionVisible] = useState(false);
  const [showBlockInterpreterModal, setShowBlockInterpreterModal] = useState(false);
  const [showAwardInterpreterModal, setShowAwardInterpreterModal] = useState(false);
  const [showApplyInterpreterDrawer, setShowApplyInterpreterDrawer] = useState(false);
  const jobId = useGetJobIdFromParam();
  const inviteInterpreterMutation = useInviteInterpreterMutation();
  const declineInterpreterMutation = useDeclineInterpreterMutation();
  const { showToast, showErrorToast } = useToast();
  const { data: job } = useGetJobQuery(jobId);
  if (!job) return null;

  const personWarningBannerText =
    interpreter.discussion?.status !== ManagerJobSuitableInterpreterDiscussionStatus.Applied
      ? getWarningBannerText(interpreter.suitabilityStatus, intl)
      : null;
  const personErrorBannerText = getErrorBannerText(interpreter.discussion?.status, intl);

  const showInviteApplyView =
    ([
      ManagerJobSuitableInterpreterStatus.Available,
      ManagerJobSuitableInterpreterStatus.NotAvailable,
    ].includes(interpreter.suitabilityStatus) &&
      interpreter.discussion?.status !== ManagerJobSuitableInterpreterDiscussionStatus.Applied &&
      interpreter.discussion?.status !== ManagerJobSuitableInterpreterDiscussionStatus.Declined) ||
    interpreter.discussion?.status === ManagerJobSuitableInterpreterDiscussionStatus.Withdrawn;

  const handleShowActionsSection = () => {
    setIsActionSectionVisible(true);
  };

  const handleHideActionsSection = () => {
    setIsActionSectionVisible(false);
  };

  const handleShowBlockInterpreterModal = () => setShowBlockInterpreterModal(true);
  const handleCloseBlockInterpreterModal = () => {
    setShowBlockInterpreterModal(false);
    handleHideActionsSection();
  };

  const handleDeclineInterpreter = () => {
    if (!jobId) return;
    declineInterpreterMutation.mutate(
      { jobId, uid: interpreter.person.uid },
      {
        onSuccess: () => {
          showToast({
            variant: 'success',
            message: intl.formatMessage({
              id: translationKeys.job_interpreters_timeline_decline_success,
            }),
          });
        },
        onError: (error) => {
          showErrorToast(error);
        },
      },
    );
  };

  return (
    <div
      key={interpreter.person.uid}
      onMouseEnter={handleShowActionsSection}
      onMouseLeave={handleHideActionsSection}
      className={classNames(styles.personCell, {
        [styles['personCell--active']]: isActionSectionVisible,
      })}
      style={{ height: '100%' }}
    >
      <PersonDetails interpreter={interpreter} />
      {isActionSectionVisible && interpreter.suitabilityStatus && (
        <div className={styles.personActionData}>
          {personWarningBannerText && (
            <Banner className={styles.banner} text={personWarningBannerText} variant="warning" />
          )}
          {personErrorBannerText && (
            <Banner className={styles.banner} text={personErrorBannerText} variant="error" />
          )}
          {showInviteApplyView && (
            <div className={styles.personActionWrapper}>
              <Button
                className={styles.flexFull}
                icon={<FontAwesomeIcon icon={faPaperPlane} />}
                size="large"
                onClick={() =>
                  jobId &&
                  inviteInterpreterMutation.mutate({ id: jobId, uid: interpreter.person.uid })
                }
              >
                {intl.formatMessage({
                  id: translationKeys.job_interpreters_timeline_invite_button_label,
                })}{' '}
                {interpreter.discussion ? `(${interpreter.discussion.invitationsCount})` : ''}
              </Button>
              <Button
                className={styles.flexFull}
                icon={<FontAwesomeIcon icon={faMagicWandSparkles} />}
                size="large"
                variant="white"
                onClick={() => setShowApplyInterpreterDrawer(true)}
              >
                {intl.formatMessage({
                  id: translationKeys.job_interpreters_timeline_apply_button_label,
                })}
              </Button>
              {interpreter.discussion && !!interpreter.discussion.invitationsCount && (
                <IconButton
                  className={styles.flexSquare}
                  hoverText={intl.formatMessage({
                    id: translationKeys.job_interpreters_timeline_decline_button_tooltip,
                  })}
                  icon={<FontAwesomeIcon icon={faCircleXmark} />}
                  onClick={handleDeclineInterpreter}
                  isLoading={declineInterpreterMutation.isPending}
                />
              )}
              <IconButton
                className={styles.flexSquare}
                hoverText={intl.formatMessage({
                  id: translationKeys.job_interpreters_timeline_block_button_tooltip,
                })}
                icon={<FontAwesomeIcon icon={faBan} />}
                onClick={handleShowBlockInterpreterModal}
              />
            </div>
          )}
          {interpreter.discussion?.status ===
            ManagerJobSuitableInterpreterDiscussionStatus.Applied &&
            interpreter.suitabilityStatus !== ManagerJobSuitableInterpreterStatus.Blocked && (
              <div className={styles.personActionWrapper}>
                <Button
                  className={styles.flexFull}
                  icon={<FontAwesomeIcon icon={faMagicWandSparkles} />}
                  size="large"
                  onClick={() => setShowAwardInterpreterModal(true)}
                >
                  {intl.formatMessage({
                    id: translationKeys.job_interpreters_timeline_award_button_label,
                  })}
                </Button>
                <Button
                  className={styles.flexSmall}
                  icon={<FontAwesomeIcon icon={faCircleXmark} />}
                  size="large"
                  variant="danger-outline"
                  onClick={handleDeclineInterpreter}
                >
                  {intl.formatMessage({
                    id: translationKeys.job_interpreters_timeline_decline_button_label,
                  })}
                </Button>
                <Button
                  className={styles.flexSmall}
                  icon={<FontAwesomeIcon icon={faBan} />}
                  size="large"
                  variant="danger-outline"
                  onClick={handleShowBlockInterpreterModal}
                >
                  {intl.formatMessage({
                    id: translationKeys.job_interpreters_timeline_block_button_label,
                  })}
                </Button>
              </div>
            )}
          {interpreter.suitabilityStatus === ManagerJobSuitableInterpreterStatus.Blocked && (
            <div className={styles.personActionWrapper}>
              <Banner
                className={classNames(styles.flexFull, styles.banner)}
                text={intl.formatMessage({
                  id: translationKeys.job_interpreters_timeline_banner_blocked_interpreter,
                })}
                variant="error"
              />
              <IconButton
                hoverText={intl.formatMessage({
                  id: translationKeys.job_interpreters_timeline_unblock_button_tooltip,
                })}
                icon={<img src={circleUnblockIcon} alt="Unblock" />}
                onClick={handleShowBlockInterpreterModal}
              />
            </div>
          )}
        </div>
      )}
      <BlockedInterpreterStatusModal
        jobId={jobId}
        interpreter={interpreter}
        show={showBlockInterpreterModal}
        onClose={handleCloseBlockInterpreterModal}
        isUnblock={interpreter.suitabilityStatus === ManagerJobSuitableInterpreterStatus.Blocked}
      />
      <ApplyInterpreterDrawer
        jobId={jobId}
        show={showApplyInterpreterDrawer}
        onClose={() => setShowApplyInterpreterDrawer(false)}
        interpreter={interpreter}
        sessionType={job.interpretationRequirement.sessionType}
      />
      <AwardInterpreterModal
        interpreter={interpreter}
        jobId={jobId}
        show={showAwardInterpreterModal}
        onClose={() => setShowAwardInterpreterModal(false)}
      />
    </div>
  );
}
