import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { faComment, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tag } from '@skiwo/components';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import InterpreterCard from '../../../components/InterpreterCard/InterpreterCard';
import NotesDrawer from '../../../Drawers/NotesDrawer/NotesDrawer';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobStatus } from '../../../types';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import { jobSectionVisibilityGuard } from '../../utils/jobSectionVisibilityGuard';
import { WithdrawModal } from '../WithdrawModal/WithdrawModal';
import styles from './AwardedInterpreterCard.module.scss';

const AwardedInterpreterCard = () => {
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const interpreter = job?.acceptedInterpreter;

  if (!interpreter) return null;

  return (
    <>
      <InterpreterCard
        interpreter={interpreter}
        actions={
          <div className={styles.actionButtons}>
            <Button
              size="large"
              variant="white"
              icon={<FontAwesomeIcon icon={faComment} />}
              onClick={() => setShowNotesDrawer(true)}
            >
              <FormattedMessage
                id={translationKeys.job_interpreter_notes}
                values={{
                  notesCount:
                    interpreter.notesCount !== undefined && interpreter.notesCount >= 0
                      ? `(${interpreter.notesCount})`
                      : '',
                }}
              />
            </Button>
            {jobSectionVisibilityGuard({
              currentStatus: job.status,
              statuses: [ManagerJobStatus.Accepted, ManagerJobStatus.InProgress],
            }) && (
              <Button
                variant="white"
                className={styles.withdrawButton}
                size="large"
                icon={<FontAwesomeIcon icon={faXmark} />}
                onClick={() => setShowWithdrawModal(true)}
              >
                <FormattedMessage id={translationKeys.job_withdraw_button} />
              </Button>
            )}
          </div>
        }
        statusTags={
          <>
            <Tag color="success" size="medium">
              <FormattedMessage id={translationKeys.job_interpreter_awared} />
            </Tag>
            {interpreter.hasStandbyForJob && (
              <Tag color="neutral" size="medium">
                <FormattedMessage id={translationKeys.job_interpreter_standby} />
              </Tag>
            )}
          </>
        }
      />
      <NotesDrawer
        show={showNotesDrawer}
        onClose={() => setShowNotesDrawer(false)}
        onCancel={() => setShowNotesDrawer(false)}
        user={{
          id: interpreter.id,
          name: interpreter.person.name,
          email: interpreter.person.email,
          phone: interpreter.person.phone,
          uid: interpreter.person.uid,
        }}
      />

      <WithdrawModal
        interpreter={interpreter}
        show={showWithdrawModal}
        onClose={() => setShowWithdrawModal(false)}
      />
    </>
  );
};

export default AwardedInterpreterCard;
