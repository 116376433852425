import { InterpretersTimelineFilterField } from './InterpretersTimelineFilters';

const getInterpretersTimelineFilters = (
  currentFilters: Record<string, string | string[] | undefined>,
  field: InterpretersTimelineFilterField,
  value: string | string[],
): Record<string, string | string[] | undefined> => {
  if (field === InterpretersTimelineFilterField.Name) {
    return { ...currentFilters, 'filter[query]': value };
  }

  if (field === InterpretersTimelineFilterField.Qualification) {
    if (Array.isArray(value) && value.length >= 0) {
      return { ...currentFilters, 'filter[qualificationIds]': value };
    }
  }

  if (field === InterpretersTimelineFilterField.Gender) {
    return { ...currentFilters, 'filter[gender]': value };
  }

  if (field === InterpretersTimelineFilterField.Distance) {
    return { ...currentFilters, 'filter[maxTravelDistanceKm]': value };
  }

  if (field === InterpretersTimelineFilterField.AlternativeLanguage) {
    if (value === 'true') {
      return { ...currentFilters, 'filter[matchingLanguagePair]': 'alternative' };
    } else {
      return { ...currentFilters, 'filter[matchingLanguagePair]': undefined };
    }
  }

  if (field === InterpretersTimelineFilterField.StandbyInterpreters) {
    return { ...currentFilters, 'filter[hasMatchingStandbySlot]': value };
  }

  return currentFilters;
};

export default getInterpretersTimelineFilters;
