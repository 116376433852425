import React from 'react';
import { useIntl } from 'react-intl';
import { Banner, Modal } from '@skiwo/components';
import { useAwardInterpreterMutation } from '../../../../Api/Endpoints/Jobs/Jobs.hooks';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import {
  ManagerJobSuitableInterpreter,
  ManagerJobSuitableInterpreterStatus,
} from '../../../../types/ManagerJobSuitableInterpreter';
import { SuitableInterpreterCard } from '../../SuitableInterpreterCard/SuitableInterpreterCard';
import { getReasonText, SuitabilityReason } from '../helpers/InterpretersTimeline.helpers';
import styles from './AwardInterpreterModal.module.scss';

export default function AwardInterpreterModal({
  interpreter,
  jobId,
  show,
  onClose,
}: {
  interpreter: ManagerJobSuitableInterpreter;
  jobId?: string;
  show: boolean;
  onClose: () => void;
}) {
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const awardInterpreterMutation = useAwardInterpreterMutation();
  const reasons = Object.entries(interpreter.suitability)
    .map(([key, val]) => (val === false ? key : null))
    .filter(Boolean);

  return (
    <Modal
      size="large"
      title={intl.formatMessage({ id: translationKeys.job_award_interpreter_modal_label })}
      description={intl.formatMessage({
        id: translationKeys.job_award_interpreter_modal_description,
      })}
      show={show}
      onHide={onClose}
      submitButtonText={intl.formatMessage({
        id: translationKeys.job_award_interpreter_modal_award,
      })}
      onCancel={onClose}
      onSubmit={async () => {
        if (!jobId) return;
        awardInterpreterMutation.mutate(
          { jobId, uid: interpreter.person.uid },
          {
            onSuccess: () => {
              onClose();
            },
            onError: (error) => {
              showErrorToast(error);
            },
          },
        );
      }}
    >
      <SuitableInterpreterCard interpreter={interpreter} />
      {interpreter.suitabilityStatus !== ManagerJobSuitableInterpreterStatus.Available &&
        !!reasons.length && (
          <Banner
            variant="warning"
            text={intl.formatMessage(
              {
                id: translationKeys.job_award_interpreter_not_suitable,
              },
              {
                reasons: reasons
                  .map((reason) => reason && getReasonText(reason as SuitabilityReason, intl))
                  .join(', '),
              },
            )}
            className={styles.banner}
          />
        )}
    </Modal>
  );
}
