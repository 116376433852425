import React from 'react';
import InterpreterCard from '../../../components/InterpreterCard/InterpreterCard';
import { ManagerJobSuitableInterpreter } from '../../../types/ManagerJobSuitableInterpreter';
import styles from './SuitableInterpreterCard.module.scss';

interface SuitableInterpreterCardProps {
  interpreter: ManagerJobSuitableInterpreter;
}

export const SuitableInterpreterCard = ({ interpreter }: SuitableInterpreterCardProps) => {
  return (
    <div className={styles.interpreterCardWrapper}>
      <InterpreterCard
        interpreter={{
          id: interpreter.person.id,
          city: interpreter.personAddress.city,
          country: interpreter.personAddress.country,
          person: {
            avatar: interpreter.person.avatar,
            email: interpreter.person.email,
            gender: interpreter.person.gender,
            name: interpreter.person.name,
            phone: interpreter.person.phone,
            phoneCode: interpreter.person.phoneCode,
            phoneNumber: interpreter.person.phoneNumber,
            uid: interpreter.person.uid,
          },
          skills: [
            ...(interpreter.mainSkill
              ? [
                  {
                    id: interpreter.mainSkill.id,
                    languageFromId: interpreter.mainSkill.sourceLanguage.id,
                    languageToId: interpreter.mainSkill.targetLanguage.id,
                    qualificationId: interpreter.mainSkill.qualification.id,
                  },
                ]
              : []),
            ...(interpreter.alternativeSkill
              ? [
                  {
                    id: interpreter.alternativeSkill.id,
                    languageFromId: interpreter.alternativeSkill.sourceLanguage.id,
                    languageToId: interpreter.alternativeSkill.targetLanguage.id,
                    qualificationId: interpreter.alternativeSkill.qualification.id,
                  },
                ]
              : []),
          ],
        }}
        className={styles.interpreterCard}
      />
    </div>
  );
};
