import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCircleDollar, faInfoCircle, faMap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Banner,
  Button,
  Drawer,
  RadioOptions,
  Skeleton,
  Table,
  TextField,
} from '@skiwo/components';
import { getFormattedAddress } from '@skiwo/utils';
import classNames from 'classnames';
import {
  useApplyInterpreterMutation,
  useGetPreviewSupplierInvoiceQuery,
} from '../../../../Api/Endpoints/Jobs/Jobs.hooks';
import useDebounce from '../../../../hooks/useDebounce';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobSessionType } from '../../../../types';
import { JobInvoiceLineGroup, JobInvoiceLineTravelType } from '../../../../types/JobInvoice';
import { ManagerJobSuitableInterpreter } from '../../../../types/ManagerJobSuitableInterpreter';
import { SuitableInterpreterCard } from '../../SuitableInterpreterCard/SuitableInterpreterCard';
import styles from './ApplyInterpreterDrawer.module.scss';

export enum TravelMethodOptions {
  AlreadyAtLocation = 'already_at_location',
  PublicTransport = 'public_transport',
  Driving = 'car',
}

export type ApplyInterpreterTravelType = Omit<
  TravelMethodOptions,
  TravelMethodOptions.AlreadyAtLocation
> | null;

interface ApplyInterpreterDrawerProps {
  jobId?: string;
  show: boolean;
  onClose: () => void;
  interpreter: ManagerJobSuitableInterpreter;
  sessionType: ManagerJobSessionType;
}

export const ApplyInterpreterDrawer = ({
  jobId,
  show,
  onClose,
  interpreter,
  sessionType,
}: ApplyInterpreterDrawerProps) => {
  const intl = useIntl();
  const applyInterpreterMutation = useApplyInterpreterMutation();
  const { showToast, showErrorToast } = useToast();
  const [selectedTravelMethod, setSelectedTravelMethod] = useState<TravelMethodOptions>(
    TravelMethodOptions.AlreadyAtLocation,
  );

  // Figure out how to avoid the debounced values
  const [travelTime, setTravelTime] = useState<string>('');
  const [debouncedTravelTime, setDebouncedTravelTime] = useState<string>('');
  const [transportTickets, setTransportTickets] = useState<string>('');
  const [debouncedTransportTickets, setDebouncedTransportTickets] = useState<string>('');
  const debounce = useDebounce(500);

  const { data, isLoading } = useGetPreviewSupplierInvoiceQuery({
    id: jobId,
    supplierUid: interpreter.person.uid,
    travelType:
      selectedTravelMethod === TravelMethodOptions.AlreadyAtLocation ? null : selectedTravelMethod,
    travelDuration:
      selectedTravelMethod === TravelMethodOptions.PublicTransport && debouncedTravelTime
        ? parseInt(debouncedTravelTime)
        : undefined,
    travelExpenses:
      selectedTravelMethod === TravelMethodOptions.PublicTransport && debouncedTransportTickets
        ? parseFloat(debouncedTransportTickets)
        : undefined,
    options: {
      enabled: show,
    },
  });

  const invoice = data?.invoice;
  const pricing = data?.pricing;

  const invoiceLines = invoice?.lines?.filter(
    (line) => line.group !== JobInvoiceLineGroup.Travel && line.quantity !== '0.0',
  );
  const travelLines = invoice?.lines?.filter(
    (line) => line.group === JobInvoiceLineGroup.Travel && line.quantity !== '0.0',
  );

  const travelFlatAmountLine = invoice?.lines?.find((line) => line.type === 'travel_flat_amount');
  const shouldShowReimbursementBanner =
    travelFlatAmountLine && travelFlatAmountLine.amount !== '0.0';

  const validateTravelTime = () => {
    if (travelTime === '') return true;

    return Boolean(
      travelTime &&
        pricing?.maxTravelDuration &&
        parseFloat(travelTime) <= pricing.maxTravelDuration,
    );
  };

  const validateTransportTickets = () => {
    if (transportTickets === '') return true;

    return Boolean(
      transportTickets &&
        pricing?.maxTravelExpenses &&
        parseFloat(transportTickets) <= parseFloat(pricing.maxTravelExpenses),
    );
  };

  const handleSubmit = () => {
    if (!jobId) return;

    applyInterpreterMutation.mutate(
      {
        id: jobId,
        uid: interpreter.person.uid,
        travelType:
          sessionType === ManagerJobSessionType.InPerson
            ? selectedTravelMethod === TravelMethodOptions.AlreadyAtLocation
              ? null
              : selectedTravelMethod
            : null,
        travelTime:
          sessionType === ManagerJobSessionType.InPerson &&
          selectedTravelMethod === TravelMethodOptions.PublicTransport &&
          debouncedTravelTime
            ? parseFloat(debouncedTravelTime)
            : undefined,
        travelTransportTickets:
          sessionType === ManagerJobSessionType.InPerson &&
          selectedTravelMethod === TravelMethodOptions.PublicTransport &&
          debouncedTransportTickets
            ? parseFloat(debouncedTransportTickets)
            : undefined,
      },
      {
        onSuccess: () => {
          onClose();
          showToast({
            variant: 'success',
            message: intl.formatMessage({
              id: translationKeys.job_apply_interpreter_drawer_success_toast,
            }),
          });
        },
        onError: (error) => {
          showErrorToast(error);
        },
      },
    );
  };

  const handlePublicTransportValueChange = (
    value: string,
    setValue: (val: string) => void,
    setDebouncedValue: (val: string) => void,
  ) => {
    setValue(value);
    debounce(() => setDebouncedValue(value));
  };

  return (
    <Drawer
      show={show}
      title={intl.formatMessage({ id: translationKeys.job_apply_interpreter_drawer_label })}
      onClose={onClose}
    >
      <div className={styles.applyInterpreterDrawer}>
        <div>
          <SuitableInterpreterCard interpreter={interpreter} />
        </div>
        {selectedTravelMethod === TravelMethodOptions.PublicTransport &&
          pricing?.maxTravelExpenses && (
            <Banner
              variant="information"
              text={intl.formatMessage(
                {
                  id: translationKeys.job_apply_interpreter_drawer_public_transport_banner,
                },
                { amount: pricing.maxTravelExpenses },
              )}
            />
          )}
        {selectedTravelMethod === TravelMethodOptions.Driving && pricing?.maxTravelExpenses && (
          <Banner
            variant="information"
            text={intl.formatMessage(
              {
                id: translationKeys.job_apply_interpreter_drawer_driving_banner,
              },
              { amount: pricing.maxTravelExpenses },
            )}
          />
        )}
        {sessionType === ManagerJobSessionType.InPerson && (
          <RadioOptions
            label={intl.formatMessage({
              id: translationKeys.job_apply_interpreter_drawer_travel_method,
            })}
            options={[
              {
                id: TravelMethodOptions.AlreadyAtLocation,
                title: intl.formatMessage({
                  id: translationKeys.job_apply_interpreter_drawer_already_at_location,
                }),
              },
              {
                id: TravelMethodOptions.PublicTransport,
                title: intl.formatMessage({
                  id: translationKeys.job_apply_interpreter_drawer_public_transport,
                }),
              },
              {
                id: TravelMethodOptions.Driving,
                title: intl.formatMessage({
                  id: translationKeys.job_apply_interpreter_drawer_driving,
                }),
              },
            ]}
            selected={selectedTravelMethod}
            onSelect={(selectedId) => setSelectedTravelMethod(selectedId as TravelMethodOptions)}
          />
        )}
        <>
          <span className={styles.tableTitle}>
            <FontAwesomeIcon icon={faCircleDollar} />
            <FormattedMessage
              id={translationKeys.job_apply_interpreter_drawer_interpreter_payout}
            />
          </span>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <span className={styles.tableHeader}>
                    <FormattedMessage
                      id={translationKeys.job_apply_interpreter_drawer_services_column}
                    />
                  </span>
                </th>
                <th>
                  <span className={classNames(styles.tableHeader)}>
                    <FormattedMessage
                      id={translationKeys.job_apply_interpreter_drawer_rate_column}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className={styles.table}>
              {isLoading &&
                [...Array(3)].map((_, i) => (
                  <tr key={i}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              {invoiceLines
                ?.filter((line) => line.amount !== '0.0')
                .map((line) => (
                  <tr key={line.name}>
                    <td>{line.name}</td>
                    <td>
                      <span className={styles.textRight}>
                        {line.amount && parseFloat(line.amount).toFixed(2)} NOK
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
        {sessionType === ManagerJobSessionType.InPerson && !isLoading && (
          <>
            <span className={styles.tableTitle}>
              <FontAwesomeIcon icon={faMap} />
              <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_travel} />
            </span>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <span className={styles.tableHeader}>
                      <FormattedMessage
                        id={translationKeys.job_apply_interpreter_drawer_services_column}
                      />
                    </span>
                  </th>
                  <th>
                    <span className={classNames(styles.tableHeader)}>
                      <FormattedMessage
                        id={translationKeys.job_apply_interpreter_drawer_rate_column}
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className={styles.table}>
                <tr>
                  <td>
                    <div className={styles.travel}>
                      <span className={styles.travelLabel}>
                        <FormattedMessage
                          id={translationKeys.job_apply_interpreter_drawer_travel_from}
                        />
                      </span>
                      {getFormattedAddress({
                        line1: pricing?.travelFrom?.line1,
                        line2: pricing?.travelFrom?.line2,
                        postcode: pricing?.travelFrom?.postcode,
                        city: pricing?.travelFrom?.city,
                        county: pricing?.travelFrom?.county,
                        country: pricing?.travelFrom?.country,
                      })}
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div className={styles.travel}>
                      <span className={styles.travelLabel}>
                        <FormattedMessage
                          id={translationKeys.job_apply_interpreter_drawer_travel_to}
                        />
                      </span>
                      {getFormattedAddress({
                        line1: pricing?.travelTo?.line1,
                        line2: pricing?.travelTo?.line2,
                        postcode: pricing?.travelTo?.postcode,
                        city: pricing?.travelTo?.city,
                        county: pricing?.travelTo?.county,
                        country: pricing?.travelTo?.country,
                      })}
                    </div>
                  </td>
                  <td></td>
                </tr>
                {selectedTravelMethod === TravelMethodOptions.PublicTransport && (
                  <>
                    <tr>
                      <td>
                        <div className={styles.travel}>
                          <FormattedMessage
                            id={translationKeys.job_apply_interpreter_drawer_travel_time}
                          />
                          <span
                            className={classNames(styles.flexRow, styles.travelLabel, {
                              [styles.warning]: !validateTravelTime(),
                            })}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <FormattedMessage
                              id={translationKeys.job_apply_interpreter_drawer_max_travel_duration}
                              values={{ hours: pricing?.maxTravelDuration }}
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className={classNames(styles.gridRow, styles.textRight)}>
                          <TextField
                            name="travelTime"
                            placeholder="0"
                            textAlign="right"
                            type="number"
                            onChange={(e) => {
                              handlePublicTransportValueChange(
                                e.target.value,
                                setTravelTime,
                                setDebouncedTravelTime,
                              );
                            }}
                            value={travelTime}
                            isInvalid={!validateTravelTime()}
                          />
                          <span>hr</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={styles.travel}>
                          <FormattedMessage
                            id={translationKeys.job_apply_interpreter_drawer_transport_tickets}
                          />
                          <span
                            className={classNames(styles.flexRow, styles.travelLabel, {
                              [styles.warning]: !validateTransportTickets(),
                            })}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <FormattedMessage
                              id={translationKeys.job_apply_interpreter_drawer_max_travel_expenses}
                              values={{
                                expenses:
                                  pricing?.maxTravelExpenses &&
                                  parseFloat(pricing.maxTravelExpenses).toFixed(2),
                              }}
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className={classNames(styles.gridRow, styles.textRight)}>
                          <TextField
                            name="transportTickets"
                            placeholder="0"
                            textAlign="right"
                            type="number"
                            onChange={(e) => {
                              handlePublicTransportValueChange(
                                e.target.value,
                                setTransportTickets,
                                setDebouncedTransportTickets,
                              );
                            }}
                            value={transportTickets}
                            isInvalid={!validateTransportTickets()}
                          />
                          <span>NOK</span>
                        </span>
                      </td>
                    </tr>
                  </>
                )}
                {travelLines?.map((line) => {
                  if (line.type === JobInvoiceLineTravelType.TravelDuration) {
                    return (
                      <tr key={line.name}>
                        <td>
                          <div className={styles.travel}>
                            <span className={styles.travelLabel}>{line.name}</span>
                            <span>
                              <FormattedMessage
                                id={translationKeys.job_apply_interpreter_drawer_travel_time_value}
                                values={{ time: line.quantity }}
                              />
                            </span>
                            <span
                              className={classNames(styles.flexRow, styles.travelLabel, {
                                [styles.warning]:
                                  line.quantity &&
                                  pricing?.maxTravelDuration &&
                                  parseFloat(line.quantity) > pricing.maxTravelDuration,
                              })}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              <FormattedMessage
                                id={translationKeys.job_apply_interpreter_drawer_max_payable_time}
                                values={{ time: pricing?.maxTravelDuration }}
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className={styles.textRight}>
                            {line.amount && parseFloat(line.amount).toFixed(2)} NOK
                          </span>
                        </td>
                      </tr>
                    );
                  } else if (line.type === JobInvoiceLineTravelType.TravelDriving) {
                    return (
                      <tr key={line.name}>
                        <td>
                          <div className={styles.travel}>
                            <span className={styles.travelLabel}>
                              <FormattedMessage
                                id={translationKeys.job_apply_interpreter_drawer_travel_distance}
                              />
                            </span>
                            <span>
                              <FormattedMessage
                                id={
                                  translationKeys.job_apply_interpreter_drawer_travel_distance_value
                                }
                                values={{ distance: line.quantity }}
                              />
                            </span>
                            <span
                              className={classNames(styles.flexRow, styles.travelLabel, {
                                [styles.warning]:
                                  line.quantity &&
                                  pricing?.maxTravelDistance &&
                                  parseFloat(line.quantity) > pricing.maxTravelDistance,
                              })}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              <FormattedMessage
                                id={
                                  translationKeys.job_apply_interpreter_drawer_max_payable_distance
                                }
                                values={{ distance: pricing?.maxTravelDistance }}
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className={styles.textRight}>
                            {line.amount && parseFloat(line.amount).toFixed(2)} NOK
                          </span>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={line.name}>
                        <td>{line.name}</td>
                        <td>
                          <span className={styles.textRight}>
                            {line.amount && parseFloat(line.amount).toFixed(2)} NOK
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          </>
        )}
        {isLoading && (
          <div className={classNames(styles.totalPriceRow)}>
            <div className={styles.totalPriceRowLoader}>
              <Skeleton />
            </div>
            <div className={styles.totalPriceRowLoader}>
              <Skeleton />
            </div>
          </div>
        )}
        {invoice?.totalAmount && (
          <div className={styles.totalPriceRow}>
            <span>
              <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_total} />
            </span>
            <span className={classNames(styles.price, styles.textRight)}>
              {selectedTravelMethod === TravelMethodOptions.PublicTransport && transportTickets
                ? (parseFloat(transportTickets) + invoice?.totalAmount).toFixed(2)
                : invoice?.totalAmount.toFixed(2)}{' '}
              NOK
            </span>
          </div>
        )}

        {selectedTravelMethod === TravelMethodOptions.Driving && shouldShowReimbursementBanner && (
          <Banner
            variant="information"
            text={intl.formatMessage({
              id: translationKeys.job_apply_interpreter_drawer_driving_customer_not_reimburse_banner,
            })}
          />
        )}
        <div className={styles.buttons}>
          <Button onClick={onClose} variant="gray" size="large">
            <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_cancel} />
          </Button>

          <Button
            data-testid="submit-button"
            iconPosition="left"
            size="large"
            variant="primary"
            type="submit"
            isLoading={applyInterpreterMutation.isPending}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_apply} />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
