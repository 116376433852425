import { ApplyInterpreterTravelType } from '../../../Job/JobDetails/InterpretersTimeline/ApplyInterpreterDrawer/ApplyInterpreterDrawer';
import {
  JobCategoriesResponse,
  JobNoteResponse,
  ManagerJobsResponse,
  ManagerJobStats,
} from '../../../types';
import { JobInvitationLogResponse } from '../../../types/JobInvitationLog';
import { JobInvoice } from '../../../types/JobInvoice';
import { JobInvoicePreview } from '../../../types/JobInvoicePreview';
import { JobSystemLogResponse } from '../../../types/JobSystemLog';
import {
  ManagerJobCancellationReason,
  ManagerJobCancelResponse,
  ManagerJobDetails,
  ManagerJobDowngradeRequirement,
  ManagerJobResponse,
} from '../../../types/ManagerJob';
import { ManagerJobStatement, ManagerJobUpdateStatement } from '../../../types/ManagerJobStatement';
import { ManagerJobSuitableInterpreterResponse } from '../../../types/ManagerJobSuitableInterpreter';
import fetchApi, { UrlParams } from '../../fetchApi';

export default {
  createJob: (body: FormData) => {
    return fetchApi<ManagerJobResponse>(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs`,
        body,
      },
      true,
    );
  },
  updateJob: (id: number, body: FormData) => {
    return fetchApi<ManagerJobResponse>(
      {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}`,
        body,
      },
      true,
    );
  },
  cancelJob: (
    id: number,
    body?: {
      reason: ManagerJobCancellationReason;
      other: boolean;
      description?: string;
      send_email: boolean;
      cancellation_date?: Date;
      admin_other?: string;
      real_person_name?: string;
    },
  ) => {
    return fetchApi<ManagerJobCancelResponse>(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/cancel`,
        body,
      },
      true,
    );
  },
  getJobs: (urlParams: UrlParams) => {
    return fetchApi<ManagerJobsResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/dashboards/manager_jobs`,
        urlParams,
      },
      true,
    );
  },
  getJob: (id: string) => {
    return fetchApi<ManagerJobDetails>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}`,
      },
      true,
    );
  },
  checkForDuplicateJobIds: (body: FormData) => {
    return fetchApi<{
      ids: number[];
    }>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/duplicate_job_ids`,
      body,
    });
  },
  getJobsStats: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobStats>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/dashboards/manager_job_stats`,
        loadingCallback,
      },
      true,
    );
  },
  getCategories: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<JobCategoriesResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/job_categories`,
      loadingCallback,
    });
  },
  getJobNotes: (jobId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<JobNoteResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages`,
        loadingCallback,
      },
      true,
    );
  },
  postJobNote: (jobId: string, note: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages`,
        body: { message: { text: note } },
        loadingCallback,
      },
      true,
    );
  },
  updateJobNote: (
    jobId: string,
    noteId: string,
    note: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages/${noteId}`,
      body: { message: { text: note } },
      loadingCallback,
    });
  },
  deleteJobNote: (
    jobId: string,
    noteId: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages/${noteId}`,
      loadingCallback,
    });
  },
  getJobStatement: (id: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobStatement>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/statements`,
      loadingCallback,
    });
  },
  updateJobStatement: (
    jobId: string,
    statementId: string,
    statement: Partial<ManagerJobUpdateStatement>,
  ) => {
    return fetchApi(
      {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/statements/${statementId}`,
        body: statement,
      },
      true,
    );
  },
  getJobDemanderInvoice: (jobId: string) => {
    return fetchApi<JobInvoice>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/invoices/demander_invoice`,
      },
      true,
    );
  },
  getJobSupplierInvoice: (jobId: string) => {
    return fetchApi<JobInvoice>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/invoices/supplier_invoice`,
      },
      true,
    );
  },
  lockJob: (jobId: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/lock`,
      },
      true,
    );
  },
  downgradeJobRequirement: (
    jobId: string,
    downgradeRequirement: ManagerJobDowngradeRequirement,
  ) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/downgrade_requirement`,
        body: {
          requirementParameter: downgradeRequirement,
        },
      },
      true,
    );
  },
  setReadyForFinance: (jobId: string, reason?: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/set_ready_for_finance`,
        body: { reason },
      },
      true,
    );
  },
  unsetReadyForFinance: (jobId: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/set_not_ready_for_finance`,
      },
      true,
    );
  },
  finishJob: (jobId: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/finish`,
      },
      true,
    );
  },

  withdrawInterpreter: (jobId: string, body: { supplierNote?: string; internalNote?: string }) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/discussions/withdraw`,
        body,
      },
      true,
    );
  },

  getSystemLogs: (id: string, urlParams: UrlParams) => {
    return fetchApi<JobSystemLogResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/system_logs`,
        urlParams,
      },
      true,
    );
  },
  getInvitationLogs: (id: string, urlParams: UrlParams) => {
    return fetchApi<JobInvitationLogResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/invitation_logs`,
        urlParams,
      },
      true,
    );
  },
  getSuitableInterpreters: (id: string, urlParams: UrlParams) => {
    return fetchApi<ManagerJobSuitableInterpreterResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/suitable_interpreters`,
        urlParams,
      },
      true,
    );
  },
  inviteInterpreter: (jobId: string, uid: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/discussions/${uid}/invite`,
      },
      true,
    );
  },
  applyInterpreter: (
    jobId: string,
    uid: string,
    travelType?: ApplyInterpreterTravelType,
    travelTime?: number,
    travelTransportTickets?: number,
  ) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/discussions/${uid}/apply`,
        body: { travelType, travelDuration: travelTime, travelExpenses: travelTransportTickets },
      },
      true,
    );
  },
  awardInterpreter: (jobId: string, uid: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/discussions/${uid}/award`,
      },
      true,
    );
  },
  blockInterpreter: (jobId: string, uid: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/interpreters/${uid}/block`,
      },
      true,
    );
  },
  unblockInterpreter: (jobId: string, uid: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/interpreters/${uid}/unblock`,
      },
      true,
    );
  },
  declineInterpreter: (jobId: string, uid: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/discussions/${uid}/decline`,
      },
      true,
    );
  },
  getPreviewSupplierInvoice: ({
    id,
    supplierUid,
    travelType,
    travelDuration,
    travelExpenses,
  }: {
    id: string;
    supplierUid?: string;
    travelType?: ApplyInterpreterTravelType;
    travelDuration?: number;
    travelExpenses?: number;
  }) => {
    return fetchApi<JobInvoicePreview>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/invoices/supplier_invoice/${supplierUid}/preview`,
        urlParams: { travelType, travelDuration, travelExpenses },
      },
      true,
    );
  },
};
