import { useEffect, useRef } from 'react';

export function useScrollToFormikError(errorKeys?: string[], submitCount?: number) {
  const ref = useRef<HTMLFormElement>(null);
  const lastSubmitCount = useRef(0);

  useEffect(() => {
    if (!errorKeys?.length || submitCount === undefined || submitCount === lastSubmitCount.current)
      return;

    lastSubmitCount.current = submitCount;

    // Wait for errors to be rendered before scrolling
    setTimeout(() => {
      const errorElement = document.querySelector('[class*="errorMessage"], [data-error]');
      if (errorElement) {
        const form = ref.current;
        const scrollContainer = form?.parentElement ?? window;

        const elementTop = errorElement.getBoundingClientRect().top;
        const containerTop = form?.getBoundingClientRect().top || 0;

        const scrollPosition =
          elementTop - containerTop + (form?.scrollTop || window.scrollY) - 140;

        scrollContainer.scrollTo({
          behavior: 'smooth',
          top: scrollPosition,
        });
      }
    }, 0); // Ensures errors are fully updated
  }, [errorKeys, submitCount]);

  return ref;
}
