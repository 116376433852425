import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './InterpretersTimeline.module.scss';

const TimeslotPill = forwardRef<
  HTMLDivElement,
  {
    position: { left: number; width: number };
    variant: 'selected' | 'green' | 'gray' | 'default';
    icon?: React.ReactNode;
    title?: string;
  }
>(({ position, variant = 'default', icon, title }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(styles.timeslotPill, styles[`timeslotPill--${variant}`])}
      style={position}
    >
      {icon}
      {position.width >= 80 && title}
    </div>
  );
});

TimeslotPill.displayName = 'TimeslotPill';

export default TimeslotPill;
