import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Modal } from '@skiwo/components';
import { ApiError } from '../../../../Api';
import {
  useBlockInterpreterMutation,
  useUnblockInterpreterMutation,
} from '../../../../Api/Endpoints/Jobs/Jobs.hooks';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobSuitableInterpreter } from '../../../../types/ManagerJobSuitableInterpreter';
import { SuitableInterpreterCard } from '../../SuitableInterpreterCard/SuitableInterpreterCard';

const getTranslationBasedOnState = (isUnblock: boolean, intl: IntlShape) => {
  const keyPrefix = isUnblock ? 'job_unblock_interpreter_modal' : 'job_block_interpreter_modal';

  return {
    title: intl.formatMessage({ id: translationKeys[`${keyPrefix}_title`] }),
    description: intl.formatMessage({ id: translationKeys[`${keyPrefix}_description`] }),
    submitButtonText: intl.formatMessage({ id: translationKeys[`${keyPrefix}_submit_label`] }),
    successMessage: intl.formatMessage({ id: translationKeys[`${keyPrefix}_success_message`] }),
  };
};

export default function BlockedInterpreterStatusModal({
  jobId,
  interpreter,
  show,
  onClose,
  isUnblock,
}: {
  jobId?: string;
  isUnblock?: boolean;
  interpreter: ManagerJobSuitableInterpreter;
  show: boolean;
  onClose: () => void;
}) {
  const intl = useIntl();
  const { showErrorToast, showToast } = useToast();
  const blockMutation = useBlockInterpreterMutation();
  const unblockMutation = useUnblockInterpreterMutation();
  const translation = getTranslationBasedOnState(!!isUnblock, intl);

  return (
    <Modal
      show={show}
      onHide={onClose}
      {...translation}
      submitButtonVariant="danger"
      isSubmitDisabled={blockMutation.isPending || unblockMutation.isPending}
      size="medium"
      onCancel={onClose}
      onSubmit={async () => {
        if (!jobId) return;
        isUnblock
          ? unblockMutation.mutateAsync(
              { jobId, uid: interpreter.person.uid },
              {
                onSuccess: () => {
                  showToast({
                    message: translation.successMessage,
                    variant: 'success',
                  });
                  onClose();
                },
                onError: (error) => {
                  showErrorToast(error as ApiError);
                },
              },
            )
          : blockMutation.mutateAsync(
              { jobId, uid: interpreter.person.uid },
              {
                onSuccess: () => {
                  showToast({
                    message: translation.successMessage,
                    variant: 'success',
                  });
                  onClose();
                },
                onError: (error) => {
                  showErrorToast(error as ApiError);
                },
              },
            );
      }}
    >
      <SuitableInterpreterCard interpreter={interpreter} />
    </Modal>
  );
}
